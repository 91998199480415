<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg src="media/svg/icons/Map/Position.svg" />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/locations/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                {{ $t('MAIN_MENU.ALL_LOCATIONS') }}
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="postEntity"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  {{ $t('MAIN_MENU.ADD_LOCATION') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="isLoaded" class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.CITY') }}:</label
                    >
                    <div class="col-9">
                      <vSelect
                        v-model="location.city"
                        class="form-control form-control-solid form-control--city-select"
                        placeholder="Select city"
                        :options="cities"
                        :reduce="(city) => city['@id']"
                        label="name"
                      />
                    </div>
                  </div>
                  <div
                    v-if="currentUserCompanyModule[0] === 'gaming'"
                    class="form-group row d-flex align-items-center"
                  >
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.LESSOR') }}:</label
                    >
                    <div class="col-9">
                      <vSelect
                        v-model="location.lessor"
                        class="form-control form-control-solid form-control--city-select"
                        placeholder="Select lessor"
                        :options="lessors"
                        :reduce="(lessor) => lessor['@id']"
                        label="name"
                      />
                    </div>
                  </div>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.LOCATION_NAME') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="location.title"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="npr. City Centar ONE Split"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.DESCRIPTION') }}:</label
                    >
                    <div class="col-9">
                      <textarea
                        v-model="location.description"
                        class="form-control form-control-solid"
                        type="text"
                        rows="5"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) && this.currentUserRole === 'ROLE_ADMIN'
                    "
                    class="form-group row d-flex align-items-center"
                    style="gap: 16px"
                  >
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.LESSOR_RENT') }}:</label
                    >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-start" style="gap: 4px">
                        <input
                          type="radio"
                          id="isLessorRentYes"
                          value="true"
                          v-model="location.isLessorRent"
                          @change="onChange"
                        />
                        <label for="isLessorRentYes">{{
                          $t('GENERAL.YES')
                        }}</label>
                      </div>
                      <div class="d-flex align-items-start" style="gap: 4px">
                        <input
                          type="radio"
                          id="isLessorRentNo"
                          value="false"
                          v-model="location.isLessorRent"
                          @change="onChange"
                        />
                        <label for="isLessorRentNo">{{
                          $t('GENERAL.NO')
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.isRentalOptionsVisible">
                    <div class="d-flex align-items-center form-group row">
                      <label class="col-3"
                        >{{ $t('LOCATION_MANAGEMENT.RENTAL_TYPE') }}:</label
                      >
                      <div class="col-9">
                        <select
                          @change="onRentalTypeChange($event)"
                          class="form-control text-capitalize"
                        >
                          <option
                            v-for="item in this.rentalTypeOptions"
                            :key="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center form-group row"
                      v-if="
                        location.rentalMeta &&
                        location.rentalMeta.rentalType !== 'percentage'
                      "
                    >
                      <label class="col-3"
                        >{{
                          $t('LOCATION_MANAGEMENT.CONTRACT_RENTAL_AMOUNT')
                        }}:</label
                      >
                      <div class="col-9">
                        <input
                          v-model="location.rentalMeta.rentalAmount"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center form-group row"
                      v-if="
                        location.rentalMeta &&
                        location.rentalMeta.rentalType === 'hybrid'
                      "
                    >
                      <label class="col-3"
                        >{{ $t('LOCATION_MANAGEMENT.INCOME_LIMIT') }}:</label
                      >
                      <div class="col-9">
                        <input
                          v-model="location.rentalMeta.incomeLimit"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center form-group row"
                      v-if="
                        location.rentalMeta &&
                        location.rentalMeta.rentalType !== 'fix'
                      "
                    >
                      <label class="col-3"
                        >{{
                          $t('LOCATION_MANAGEMENT.RENTAL_PERCENTAGE')
                        }}:</label
                      >
                      <div class="col-9">
                        <input
                          v-model="location.rentalMeta.rentalPercentage"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserCompanyOib === '05959244936' &&
                      this.currentIsTopGun &&
                      (this.currentUserPersonalInfo.email.includes(
                        'davor.botincan@kidsdelux.hr'
                      ) ||
                        this.currentUserPersonalInfo.email.includes(
                          '@arsfutura.co'
                        ))
                    "
                  >
                    <div class="d-flex align-items-center form-group row">
                      <label class="col-3">Coin n-th transaction: </label>
                      <div class="col-9">
                        <input
                          v-model="location.maintenanceMeta.coinNthTransaction"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="d-flex align-items-center form-group row">
                      <label class="col-3">SMS n-th transaction: </label>
                      <div class="col-9">
                        <input
                          v-model="location.maintenanceMeta.smsNthTransaction"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="d-flex align-items-center form-group row">
                      <label class="col-3">Card n-th transaction: </label>
                      <div class="col-9">
                        <input
                          v-model="location.maintenanceMeta.cardNthTransaction"
                          class="form-control form-control-solid"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3"
                      >{{
                        $t('LOCATION_MANAGEMENT.RESPONSIBLE_WORKER')
                      }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="location.responsibleWorker"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3"
                      >{{
                        $t('LOCATION_MANAGEMENT.NEW_RESPONSIBLE_WORKER')
                      }}:</label
                    >
                    <div class="col-9">
                      <vSelect
                        v-model="location.newResponsibleWorker"
                        class="form-control form-control-solid form-control--city-select"
                        placeholder="Odaberite odgovornu osobu"
                        :options="workers"
                        :reduce="(worker) => worker['@id']"
                        label="fullName"
                      />
                    </div>
                  </div>
                </div>
                <div class="my-5" style="width: 100%">
                  <h3 class="text-dark font-weight-bold mb-10">
                    {{ $t('LOCATION_MANAGEMENT.WORKING_TIME') }}:
                  </h3>
                  <div class="mb-10">
                    <label class="text-capitalize mb-10"
                      >{{ $t('LOCATION_MANAGEMENT.MONDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.mondayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.mondayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.TUESDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.tuesdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.tuesdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.WEDNESDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.wednesdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.wednesdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.THURSDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.thursdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.thursdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.FRIDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.fridayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.fridayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.SATURDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.saturdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.saturdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label
                      class="mb-10 text-dark font-weigth-medium text-capitalize"
                      >{{ $t('LOCATION_MANAGEMENT.SUNDAY') }}:</label
                    >
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.START_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.sundayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize"
                        >{{ $t('LOCATION_MANAGEMENT.END_TIME') }}:</label
                      >
                      <div class="col-4">
                        <input
                          v-model="location.sundayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator separator-dashed my-10"></div>
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">
                    {{ $t('LOCATION_MANAGEMENT.ADDRESS') }}:
                  </h3>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.STREET_NAME') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="location.address.name"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="npr. Slavenskog 1, 10110 Zagreb"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Lat:</label>
                    <div class="col-9">
                      <input
                        v-model="location.address.lat"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Lng:</label>
                    <div class="col-9">
                      <input
                        v-model="location.address.lng"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LOCATION_MANAGEMENT.MAP') }}:</label
                    >
                    <div class="col-9">
                      <div style="height: 350px">
                        <l-map
                          v-if="showMap"
                          :zoom="zoom"
                          :center="center"
                          :options="mapOptions"
                          @update:center="centerUpdate"
                          @update:zoom="zoomUpdate"
                          @click="addMarker"
                        >
                          <l-tile-layer :url="url" :attribution="attribution" />
                          <l-marker
                            v-for="(coordinate, key) in coordinates"
                            :key="key"
                            :lat-lng="coordinate"
                            @click="removeMarker(index)"
                          >
                          </l-marker>
                        </l-map>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
          <div v-else class="col-12 text-center py-5">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import ProfileService from '@/core/services/profile.service'
import { mapGetters } from 'vuex'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon, latLng } from 'leaflet'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'LocationsNew',
  components: {
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      location: {
        mondayStartTime: null,
        mondayEndTime: null,
        tuesdayStartTime: null,
        tuesdayEndTime: null,
        wednesdayStartTime: null,
        wednesdayEndTime: null,
        thursdayStartTime: null,
        thursdayEndTime: null,
        fridayStartTime: null,
        fridayEndTime: null,
        saturdayStartTime: null,
        saturdayEndTime: null,
        sundayStartTime: null,
        sundayEndTime: null,
        title: '',
        description: '',
        address: {
          name: '',
          lat: null,
          lng: null,
        },
        isActive: true,
        responsibleWorker: null,
        newResponsibleWorker: null,
        city: '',
        company: '',
        lessor: null,
        isLessorRent: true,
        rentalMeta: {
          rentalType: '',
          rentalAmount: '',
          incomeLimit: '',
          rentalPercentage: '',
        },
        maintenanceMeta: {
          smsNthTransaction: '',
          coinNthTransaction: '',
          cardNthTransaction: '',
        },
      },
      cities: [],
      lessors: [],
      workers: [],
      zoom: 7,
      center: latLng(44.709554, 16.1646569),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      coordinates: [],
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserPersonalInfo',
      'currentIsTopGun',
      'currentUserCompanyOib'
    ]),
  },
  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Add new Location', route: '' },
    ])
    ApiService.get('cities', '?pagination=false').then(({ data }) => {
      this.cities = [...data['hydra:member']]
      this.isLoaded = true
    })

    ApiService.get('lessors', '?pagination=false').then(({ data }) => {
      this.lessors = [...data['hydra:member']]
    })

    this.location.company =
      ProfileService.getProfileAccountInfo().company_api_endpoint

    const companyId = this.currentUserCompany.replace('/api/v1/companies/', '')

    ApiService.get(
      '/users',
      `?pagination=false&company=${companyId}&order[name]=desc`
    ).then(({ data }) => {
      data['hydra:member'].forEach((user) => {
        if (
          user.roles &&
          user.roles.some((u) => u === 'ROLE_JUNIOR' || u === 'ROLE_WORKER')
        ) {
          this.workers.push({
            ...user,
            fullName: `${user.name} ${user.surname}`,
          })
        }
      })
      this.isLoaded = true
    })

    this.location.isLessorRent = true
    this.isRentalOptionsVisible = false
    this.location.rentalMeta = {
      rentalType: '',
      rentalAmount: '',
      incomeLimit: '',
      rentalPercentage: '',
    }

    if (
      this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') &&
      this.currentUserRole === 'ROLE_ADMIN'
    ) {
      this.rentalTypeOptions = ['fix', 'percentage', 'hybrid']
    } else {
      this.rentalTypeOptions = ['fix', 'percentage']
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    addMarker(event) {
      this.location.address.lat = event.latlng.lat
      this.location.address.lng = event.latlng.lng
      this.coordinates.push(event.latlng)
    },
    removeMarker(index) {
      this.coordinates.splice(index, 1)
    },
    formatTime(isoString) {
      if (!isoString) return null

      const timePart = isoString.split('T')[1]
      const timeWithoutOffset = timePart.split('+')[0].split('-')[0]

      const [hours, minutes] = timeWithoutOffset.split(':')

      return `${hours}:${minutes}`
    },
    parseEmptyString(value) {
      return value === '' ? null : value
    },
    isValidTimeRange(startTime, endTime) {
      if (!startTime || !endTime) return true // Only validate if both times are present
      return startTime < endTime
    },
    validateTimes() {
      const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]

      for (const day of days) {
        let startTime = this.parseEmptyString(this.location[`${day}StartTime`])
        let endTime = this.parseEmptyString(this.location[`${day}EndTime`])

        // Check if both start and end times are present or both are absent
        if ((startTime && !endTime) || (!startTime && endTime)) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: `Both start time and end time must be provided for ${
              day.charAt(0).toUpperCase() + day.slice(1)
            }.`,
          })
          return false
        }

        // Check if end time is after start time
        if (!this.isValidTimeRange(startTime, endTime)) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: `End time for ${
              day.charAt(0).toUpperCase() + day.slice(1)
            } must be after start time.`,
          })
          return false
        }
      }

      return true
    },
    postEntity() {
      if (!this.validateTimes()) {
        return
      }
      const formattedLocation = {
        ...this.location,
        isActive: true,
        newResponsibleWorker: this.location.newResponsibleWorker
          ? this.location.newResponsibleWorker
          : null,
        mondayStartTime: this.parseEmptyString(this.location.mondayStartTime),
        mondayEndTime: this.parseEmptyString(this.location.mondayEndTime),
        tuesdayStartTime: this.parseEmptyString(this.location.tuesdayStartTime),
        tuesdayEndTime: this.parseEmptyString(this.location.tuesdayEndTime),
        wednesdayStartTime: this.parseEmptyString(
          this.location.wednesdayStartTime
        ),
        wednesdayEndTime: this.parseEmptyString(this.location.wednesdayEndTime),
        thursdayStartTime: this.parseEmptyString(
          this.location.thursdayStartTime
        ),
        thursdayEndTime: this.parseEmptyString(this.location.thursdayEndTime),
        fridayStartTime: this.parseEmptyString(this.location.fridayStartTime),
        fridayEndTime: this.parseEmptyString(this.location.fridayEndTime),
        saturdayStartTime: this.parseEmptyString(
          this.location.saturdayStartTime
        ),
        saturdayEndTime: this.parseEmptyString(this.location.saturdayEndTime),
        sundayStartTime: this.parseEmptyString(this.location.sundayStartTime),
        sundayEndTime: this.parseEmptyString(this.location.sundayEndTime),
        isLessorRent:
          String(this.location.isLessorRent) === 'false' ? false : true,
      }
      ApiService.post('locations', formattedLocation)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Location added successfully.',
          })
          setTimeout(() => {
            this.$router.push({ name: 'locations-list' })
          }, 500)
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Failed to update location.',
          })
        })
    },
    onRentalTypeChange(event) {
      this.location.rentalMeta.rentalType = event.target.value.toLowerCase()
    },
    onChange(event) {
      if (event.target && event.target.value && event.target.value === 'true') {
        this.isRentalOptionsVisible = false
      } else {
        this.isRentalOptionsVisible = true
      }
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.basemap {
  width: 100%;
  height: 300px;
}
.form-control {
  &--city-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
